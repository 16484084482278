import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function Aim() {

  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-aim">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							{/* <div className="bradcumb text-center">
								<h3>Aim</h3>
							</div> */}
						
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200">
				<div className="container">
				
					<div className="row justify-content-center wow fadeInDown">
						<div className="col-lg-10">
							<p className="">Established in 2013, NIMAS (National Institute of Mountaineering and Adventure
Sports) is nestled in the picturesque Dirang valley within the West Kameng district of
Arunachal Pradesh. Perched atop a hill, it offers panoramic views of Dirang town and
the iconic Sela pass. As the sole national-level institute mandated to conduct
adventure courses across all three verticals – Land, Aero, and Aqua – NIMAS stands
as a pioneering initiative. Our mission is to equip Indian nationals with the skills to
navigate diverse outdoor environments and pursue careers in adventure sports. Each
training program at NIMAS is designed to encourage self-exploration and personal
growth, drawing inspiration from the remarkable journeys of individuals closely
associated with the institute. Furthermore, we are committed to fostering sustainable
employment opportunities to bolster the local economy. 


</p>
						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default Aim;
