import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function Organisation() {


  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-org">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
{/* 						
							<div className="bradcumb text-center">
								<h3>Organisation</h3>
							</div>
						 */}
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200 org">
				<div className="container">
				
                    <div class="row">
						<div class="col-lg-4 col-sm-4">
							
							<div class="single-about-adventure text-center">
								<div class="about-ad-thumb">
									<img src="images/adventure/raj.jpg" alt="" />
								</div>
								<div class="about-ad-content">
									<h4>Mr. Rajnath Singh</h4>
									<p>Defence Minister of India<br/>
President of the Institute</p>
								</div>
							</div>
							
						</div>
						<div class="col-lg-4 col-sm-4">
						
							<div class="single-about-adventure text-center">
								<div class="about-ad-thumb">
									<img src="images/adventure/pemaji.jpg" alt="" />
								</div>
								<div class="about-ad-content">
									<h4>Shri Pema Khandu</h4>
									<p>Chief Minister of Arunachal Pradesh<br/>
Vice President of the Institute</p>
								</div>
							</div>
							
						</div>
						<div class="col-lg-4 col-sm-4">
						
							<div class="single-about-adventure text-center">
								<div class="about-ad-thumb">
									<img src="images/adventure/ranveer.jpeg" alt="" />
								</div>
								<div class="about-ad-content">
									<h4>Col Ranveer Singh Jamwal SM, VSM**</h4>
									<p></p>
								</div>
							</div>
							
						</div>
					</div>

					<div className="row justify-content-center wow fadeInDown">
						<div className="col-lg-10">
							<p className="">The National Institute of Mountaineering & Adventure Sports, located in Dirang,
Arunachal Pradesh, operates under the auspices of the Ministry of Defence,
Government of India. Mr. Rajnath Singh, the Defence minister, serves as the
President of the institute, while Shri Pema Khandu, the Chief Minister, holds the
position of Vice President. The institute is overseen by Shri Satyajit Mohanty, IRS, Joint
Secretary (AF), Ministry of Defence, who acts as the Secretary. Comprising seven
members, the governing council includes representatives from both the central and
Arunachal Pradesh governments. Established on May 30, 2013, the institute
commenced its operations with the mission to promote mountaineering and adventure
sports activities. 
</p>
						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default Organisation;
